.image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 4rem;
    min-height: 4rem;

    &:empty {
        display: none;
    }
}

@use 'common' as common;

.layout {
    // The default top/bottom margins may be overridden by the content editor
    $vertical-margin-default: 1rem;

    // Set negative horizontal margin and equivalent padding in order to
    // make the background of a layout cover the padding of the app-container
    .standard {
        padding-left: common.$padding-sides-mobile;
        padding-right: common.$padding-sides-mobile;
        margin: $vertical-margin-default -#{common.$padding-sides-mobile};

        @media #{common.$mq-screen-tablet} {
            padding-left: common.$padding-sides-tablet;
            padding-right: common.$padding-sides-tablet;
            margin-left: -#{common.$padding-sides-tablet};
            margin-right: -#{common.$padding-sides-tablet};
        }

        @media #{common.$mq-screen-desktop} {
            padding-left: common.$padding-sides-desktop;
            padding-right: common.$padding-sides-desktop;
            margin-left: -#{common.$padding-sides-desktop};
            margin-right: -#{common.$padding-sides-desktop};
        }
    }

    .fullwidth {
        @include common.full-width-mixin();
    }

    .bg {
        $bgPaddingDefault: 1.3rem;
        padding-top: $bgPaddingDefault;
        padding-bottom: $bgPaddingDefault;
    }

    // This checks if a layout-section or shelf is the last one in the region (ie not a contact-area)
    // and sets the "Last updated" component to white.
    &:global(.page__single-col-page) {
        :global(.region:has(.layout__section-with-header:last-child)) ~ div {
            background-color: white;
        }

        :global(.region:has(.shelf-layout:last-child)) ~ div {
            background-color: white;
        }
    }
}

@use 'common' as common;

.testVariant {
    margin-top: var(--a-spacing-10);
    padding: var(--a-spacing-6);
    background-color: var(--a-orange-100);
    border-radius: common.$border-radius-xlarge;

    & > :not(:last-child) {
        margin-bottom: var(--a-spacing-3);
    }
}

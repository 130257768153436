@use 'common' as common;

@mixin contactBackground() {
    padding-bottom: 4rem;
    background-color: var(
        --a-gray-50
    ) !important; // To override inline colors set in XP (to be changed in the future)
    --size: 6px;
    --p: 3px;
    --R: 6px;
    mask:
        radial-gradient(var(--R) at left 50% top calc(var(--size) + var(--p)), #000 99%, #0000 101%)
            calc(50% - 2 * var(--size)) 0 / calc(4 * var(--size)) 100%,
        radial-gradient(var(--R) at left 50% top calc(-1 * var(--p)), #0000 99%, #000 101%) left 50%
            top var(--size) / calc(4 * var(--size)) 100% repeat-x;
}

.flexCols {
    &:last-child {
        padding-bottom: 4rem;
    }

    :global(.region__flexcols) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;

        @include common.content-studio-drop-zone-fix-global();
    }

    :global(.region__flexcols--1-cols) {
        @include common.flex-cols-mixin(1);
    }

    :global(.region__flexcols--2-cols) {
        @include common.flex-cols-mixin(2, 2rem);

        @media #{common.$mq-screen-mobile} {
            @include common.flex-cols-mixin(1);
        }
    }

    :global(.region__flexcols--3-cols) {
        @media #{common.$mq-screen-desktop} {
            @include common.flex-cols-mixin(3, 2rem);
        }

        @media #{common.$mq-screen-tablet} {
            @include common.flex-cols-mixin(2);
        }

        @media #{common.$mq-screen-mobile} {
            @include common.flex-cols-mixin(1);
        }
    }
}

.header {
    margin-bottom: 2.5rem;
}

.layoutSituationOrProduct {
    $singleColMaxWidth: calc(#{common.$sectionMaxWidth} + 2 * #{common.$padding-sides-mobile});
    $doubleColMaxWidth: calc(#{common.$contentMaxWidth} + 2 * #{common.$padding-sides-tablet});

    @include common.full-width-mixin();

    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 4rem;

    &:has(:global(.part__contact-option)) {
        @include contactBackground();
        padding-top: 5rem;

        .contentWrapper {
            $margin: clamp(-300px, calc((800px - 100vw) / 2), 0px);
            margin: 0 $margin;
        }

        :global(.region__flexcols--2-cols) {
            :global(.part__contact-option) {
                margin-bottom: var(--a-spacing-8);
            }
        }
    }

    :global(.region__flexcols.region__flexcols) {
        //Double class to increase specificity, to avoid media query being overridden
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;

        @include common.content-studio-drop-zone-fix-global();

        @media #{'only screen and (max-width: #{$singleColMaxWidth})'} {
            @include common.flex-cols-mixin(1);
        }
    }

    :global(.region__flexcols--1-cols) {
        @include common.flex-cols-mixin(1);
    }

    :global(.region__flexcols--2-cols) {
        @include common.flex-cols-mixin(2);
    }

    :global(.region__flexcols--3-cols) {
        @include common.flex-cols-mixin(3);

        @media #{'only screen and (max-width: #{$doubleColMaxWidth})'} {
            @include common.flex-cols-mixin(2);
        }
    }
}

.layoutSituationShelf {
    padding-top: var(--a-spacing-10);
    padding-bottom: 0rem;
}

.shelfHeader {
    color: var(--brand-purple-deep);
    margin-bottom: 1rem;
    text-wrap: balance;
    position: relative;

    &:before {
        @include common.header-before-rectangle();
    }
    :global(.navds-heading) {
      margin-top: var(--a-spacing-2);
  }
}


.shelfLayout {
    display: flex;
    gap: 0.75rem;

    > * {
        margin: 0 !important; //overstyr nth-child margin fra generell flexcols
        width: 100%;
    }
}

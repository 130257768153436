.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.25rem;
    border-width: 1px;
    border-radius: 0.5rem;
    border-style: solid;
    border-color: var(--a-gray-300);
    text-decoration: none;
    margin-bottom: var(--a-spacing-9);

    &:hover {
        background-color: #ecf3fe;
        border-color: #cbd4e1;

        .linkText {
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: 0.15625rem;
        }
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    color: var(--default-action-color);
}

.linkText.linkText {
    font-weight: 600;
    margin-bottom: 0;
}

.tagline {
    color: var(--a-text-subtle);
    font-variant: all-small-caps;
    letter-spacing: 0.075rem;
    margin-top: var(--a-spacing-05);
    text-transform: uppercase;
}

@use 'common' as common;

.userTestsEditor {
    :not(:last-child) {
        margin-bottom: 0.25rem;
    }
}

.header {
    margin-top: var(--a-spacing-10);
    border: 2px black solid;
    border-left-width: 0;
    border-right-width: 0;
    padding: 0.5rem 0;
}

.variant {
    margin-top: 1rem;
}

.disabled {
    filter: grayscale(100%) blur(3px);
    pointer-events: none;
}

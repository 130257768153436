@use 'common' as common;

.header {
  scroll-margin-top: 2.5lh;
}

.anchorLink {
    font-size: inherit;
    color: inherit;
    text-decoration: inherit;
}

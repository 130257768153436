@use '../../../../common' as common;

.warning {
    min-width: 400px;
    background-color: common.$a-white;

    &::before {
        content: '';
        display: block;
        height: 100px;
        margin-top: -100px;
        visibility: hidden;
    }
}

.lenkeInline {
    margin-left: 0.5rem;
}

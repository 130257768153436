.contactStepPage {
    padding-bottom: 1rem;
    column-gap: 1.75rem;
    margin: 1.25rem auto 2.5rem;
    display: grid;
    grid-template-columns: 1fr 40rem 1fr;
    grid-template-areas:
        'pictogram header .'
        '. content .'
        '. backLink .';

    @media only screen and (max-width: 1024px) {
        grid-template-columns: none;
        grid-template-areas:
            'pictogram'
            'header'
            'content'
            'backLink';
        padding: 0 0.5rem;
    }
    .pictogram {
        grid-area: pictogram;
        display: block;
        justify-self: end;
        width: 4.5rem;
        height: auto;
        position: relative;
        align-self: flex-start;

        @media only screen and (max-width: 1024px) {
            width: 4rem;
            justify-self: start;
        }
    }
    .header {
        grid-area: header;
        margin-bottom: 0.5rem;
    }

    .content {
        grid-area: content;
        margin-top: 0.5rem;

        .linkPanels {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            list-style-type: none;
            padding: 0;
            margin-bottom: 2rem;
            margin-top: 1.5rem;

            .linkPanel {
                border-radius: 0.5rem;
                border: 1px solid var(--a-border-subtle);

                & :global(.navds-link-panel__content) {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }

                & :global(.navds-link-panel__title) {
                    color: var(--default-action-color);
                    font-size: 1.25rem;
                }

                & :global(.navds-link-panel__chevron) {
                    display: none;
                }

                .arrowRightIcon {
                    flex-shrink: 0;
                    margin-left: 1rem;
                    color: var(--a-text-action);
                }

                &:hover {
                    background-color: #ecf3fe;
                    border-color: #cbd4e1;
                    box-shadow: none;
                }
            }
        }
    }

    .backLink {
        grid-area: backLink;
    }
}
